
import { Options, Vue } from "vue-class-component";
import axios from "@/plugins/axios";
import { ElMessageBox } from "element-plus";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

interface Res {
  status: boolean;
  message: string;
}
@Options({
  components: {
    FontAwesomeIcon,
  },
})
export default class ComfirmSubscriptionPage extends Vue {
  headers = {
    "token-user": localStorage.getItem("tokenUser"),
    account: localStorage.getItem("account"),
  };
  query: any = {};
  success = false;
  endpoint = `${process.env.PRIMARY_IP}/comfirm-subscription`;
  async created() {
    this.query = this.$route.query;
    this.$router.replace({ query: {} });
    this.handleData();
  }
  async handleData() {
    if (!this.query.accountId || this.query.status == 'true') {
      this.$message.success('Save successfully !!!');
      this.success = true;
      return;
    }  else {
    await this.$store.dispatch("setLoading", true, { root: true });
    let res: Res;
    res = await axios.get("shopify/save-connection-data", {
      headers: this.headers,
      params: this.query,
    });
    this.success = true;
    if (!res.status) {
      this.$message.warning(res.message);
      this.success = false;
    }
    await this.$store.dispatch("setLoading", false, { root: true });
    }

  }
  goDashboard() {
    this.$router.replace({ name: "DashboardPage" });
  }
}
